import { Injectable } from '@angular/core';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { interval, Subject, Subscription } from 'rxjs';
import ILogMetric = ApiClient.ILogMetric;
import Unit = ApiClient.Unit;
import StorageResolution = ApiClient.StorageResolution;

export interface LogMetricArgs {
  name: string;
  namespace: string;
  unit: Unit;
  value: number;
  storageResolution?: StorageResolution | null;
  properties?: { [key: string]: any } | null;
}

@Injectable({ providedIn: 'root' })
export class MetricLoggerService {
  private readonly _metrics: ILogMetric[] = [];
  private readonly onLog$ = new Subject();
  private _intervalFlushSub?: Subscription;
  constructor(private _log: ApiClient.LoggingService) {}

  logMetric(
    metric: LogMetricArgs,
    options?: { flush: boolean },
  ): Promise<boolean> {
    return this.logMetrics([metric], options);
  }

  async logMetrics(
    metrics: LogMetricArgs[],
    options?: { flush: boolean },
  ): Promise<boolean> {
    this._metrics.push(
      ...metrics.map((m) => ({
        ...m,
        properties: m.properties ?? null,
        storageResolution: m.storageResolution ?? null,
      })),
    );
    this.onLog$.next();
    if (options?.flush === true) {
      return await this.flush();
    }
    return true;
  }

  flushOnMsInterval(milliseconds: number = 10_000) {
    if (this._intervalFlushSub != null) {
      throw new Error(
        'Metric logger service already configured to flush on an interval.',
      );
    }
    this._intervalFlushSub = interval(milliseconds).subscribe(() =>
      this.flush(),
    );
  }

  async flush(): Promise<boolean> {
    if (this._metrics.length === 0) {
      return true;
    }
    try {
      const metrics = this._metrics.map((m) => new ApiClient.LogMetric(m));
      this._metrics.length = 0;
      await this._log
        .logMetrics(new ApiClient.LogMetricsCommand({ metrics }))
        .toPromise();
      return true;
    } catch (err) {
      return false;
    }
  }
}
